<template>
    <div class="fenbu">
        
        <div class="step " v-if="topicData">
            <p class="p1">分步思路解析</p>
            <van-collapse v-model="activeNames" accordion>
                <template v-for="(item,n) in topicData['分步']">
                    <!-- <van-collapse-item :key="n" :title="'目标'+ number[n] +'：'+item['步骤']" :name="n"> -->
                    <van-collapse-item :key="n" :name="n">
                        <template #title>
                            <div>
                                <span>目标 {{number[n]}} ： <span :id="'tit_'+n">{{item['步骤']}}</span> </span>    
                                <span class="bbuzhou" :id="'bbuzhou_'+n"></span>
                            </div>
                        </template>
                        <div class="step-info">
                            <div class="step-info-list">
                                <p class="p2">步骤{{number[n]}}：</p>
                                <div class="ta_c">
                                    <template v-for="(array,num) in item['解析']">
                                        <template v-for="(list_1,num_1) in array">
                                            <span :key="num+num_1+'_str'" v-if="list_1[0]=='str'" class="item_jiexi item">{{list_1[1]}}</span>
                                            <span :key="num+num_1+'_tex'" v-else-if="list_1[0]=='tex'" class="item_jiexi tex_item" :id="'step_list_'+n+'_'+num+'_'+num_1"></span>
                                        </template>
                                    </template>
                                </div>
                            </div>
                            <div class="step-info-list on" :key="item['答案']+n">
                                <div class="title">结果：</div>
                                <div class="result ta_c">
                                    <div class="result-jie" :id="'resultJie_'+n"></div>
                                </div>
                            </div>
                        </div>
                    </van-collapse-item>
                </template>
            </van-collapse>
        </div>
        <div class="ta_c ">
            <img v-if="lastStep" width="100" @click="clickNext(1)" src="@/assets/img/img_10.png" alt="">
            <img v-else-if="fenbuStatus == 0" width="100" @click="clickNext()" src="@/assets/img/img_9.png" alt="">
            <span v-else-if="activeNames !=='' && topicData['分步'][activeNames||0]['答案']" @click="checkResult" class="xy_jg">校验结果{{number[activeNames]}}</span>
            <img v-else width="100" @click="clickNext()" src="@/assets/img/img_9.png" alt="">
        </div>

        <!-- <div class="zongjie" @click="zongjie" :class="activeNames !==''?'on':''">
            <div class="p1"><i class="iconfont icongeneral-daoxue"></i></div>
            <div class="p2">总结</div>
            <div class="qipaoZj" :class="activeNames==0?'on':''">
                <p>支持总结具体步骤</p>
            </div>
        </div> -->

        <div class="xiayiti" @click="xiayiti" >
            <div class="p1">下一题</div>
        </div>
        
    </div>
</template>

<script>
    var $ = require( "jquery" );
    import navLeft from './component/leftNav';
    import utils from '@/assets/js/utils';
    window.$ = $;
    export default {
        name:'fenbu',
        data() {
            return {
                fenbuStatus: 0,

                lastStep: false,

                activeNames: '',
                showNum: 0,//左侧菜单展开那个
                leftInfo:{
                    showNum:0, //当前展开 
                    checkedNum:0, // 当前选中
                    data:'',
                    level:1, //难度
                },
                currentInfo:{}, // 当前页面的左侧菜单展示信息


                specialName:'',//专题名称
                titleName: '', //标题名称

                active:'',
                query: '',
                checkNum: '',
                number: ["一","二","三","四","五","六","七","八","九"],
                resultArr: [], // 是否显示下一步
            }
        },
        components: {
            navLeft,
        },
        created () {
            let query = this.$route.query;
            let navInfo = query.navInfo;
            if(typeof navInfo == 'string'){
                query.navInfo = JSON.parse(navInfo);
            }
            this.query = query;
        },
        watch: {
            activeNames(val){
                this.$nextTick(()=>{
                    this.getStep(val);
                });
                this.fenbuStatus = 2;
            },
        },
        computed: {
            topicData(){ // 获取的题的内容
                let topicData = this.$store.state.special.topicData;
                setTimeout(() => {
                    try {
                        topicData['分步'].map((e,n)=>{
                            let dom = document.getElementById('tit_'+n);
                            this.$kx.render(e['步骤'], dom, {
                                displayMode: true,
                            });
                        });
                    } catch (error) {
                        
                    }
                }, 800);
                
                return topicData;
            },
        },
        methods: {
            xiayiti(){
                this.$parent.goback();
                this.$parent.nextTopic();
            },
            zongjie(){
                console.log('this.$parent', this.$parent);
                console.log('this.activeNames', this.activeNames);
                this.$parent.$parent.showZj = true;
                this.$parent.$parent.buzhou = this.activeNames === '' ? -1 : this.activeNames;
            },
            clickNext(e){
                console.log('e',e)
                this.lastStep = false
                if(e==1){
                    this.activeNames = 0;
                }else{
                    if(this.activeNames===''){
                        this.activeNames = 0;
                    }else{
                        this.activeNames++;
                    }
                }
            },
            checkResult(){
                if(this.activeNames ===''){
                    return false;
                }
                let jie = this.topicData['分步'][this.activeNames]['答案'];
                let inputClass = $('#resultJie_'+this.activeNames).find('.inputClass');
                let topicResult=false;
                inputClass.map((n,el)=>{
                    if(el.value != jie[n]){
                        if(el.value===''){
                            el.value="?"
                        }
                        topicResult = false;
                        $(el).before('<span class="jie-err">'+el.value+'</span><span class="jie-succ">'+jie[n]+'</span>')
                    }
                    else if(el.value == jie[n]){
                        topicResult = true;
                        $(el).before('<span class="jie-succ">'+jie[n]+'</span>');
                    }
                    inputClass.hide();
                });

                this.fenbuStatus = 0;

                if(this.topicData['分步'].length - 1 == this.activeNames){
                    this.lastStep = true
                }else{
                    this.lastStep = false
                }
                
            },
            getStep(n){
                let list = this.topicData['分步'];
                try {
                    list[n]['解析'].map((array,num)=>{
                        array.map((list_1,num_1)=>{
                            if(list_1[0]=='tex'){
                                let id='step_list_'+n+'_'+num+'_'+num_1;
                                console.log(id);
                                this.$nextTick(()=>{
                                    let dom = document.getElementById(id);
                                    this.$kx.render(list_1[1], dom, {
                                        displayMode: true,
                                    });
                                });
                            }
                        })
                    });
                    let dom = document.getElementById('resultJie_'+n);
                    let jie = list[n]['解'];
                    // let jie = list[n]['解析'][this.activeNames]['解'];
                    console.log('resultJie_'+n,jie);
                    this.$kx.render(jie, dom, {
                        displayMode: true,
                    });
                    this.$nextTick(()=>{
                        let mcloseArr = $('#resultJie_'+n).find('.cjk_fallback');
                        $.each(mcloseArr, function (index) {
                            let item = mcloseArr[index];
                            if ($(item).html() == "冇") {
                                $(item).html("<input type='text' class='inputClass'>")
                            }
                        });
                    })
                } catch (error) {
                    
                }
            },

        },
    }
</script>

<style lang="less" scoped>
    .fenbu{
        padding-bottom: 80px;
        .title{
            &-p{
                height: 31px;
                font-size: 21px;
                font-family: SourceHanSansSC-Heavy, SourceHanSansSC;
                font-weight: 800;
                color: #FFFFFF;
                line-height: 31px;
                padding-bottom: 12px;
            }
            &-check{
                display: flex;
                li{
                    padding: 5px 12px;
                    height: 30px;
                    background: #7A8FE1;
                    border-radius: 15px;
                    margin-right: 8px;

                    height: 20px;
                    font-size: 14px;
                    font-family: SourceHanSansSC-Normal, SourceHanSansSC;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 20px;
                    .van-icon{
                        position: relative;
                        top: 3px;
                    }
                }
            }
            &-pair{
                padding-top: 12px;
                label{
                    height: 20px;
                    font-size: 14px;
                    font-family: SourceHanSansSC-Normal, SourceHanSansSC;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 20px;
                    padding-right: 4px;
                }
                &-con{
                    width: 269px;
                    height: 8px;
                    display: inline-block;
                    background: rgba(255, 255, 255, 0.5);
                    border-radius: 5px;
                    overflow: hidden;
                    span{
                        height: 8px;
                        float: left;
                        background: #F9C200;
                    }
                }
            }
        }
        .goback{
            color: #fff;
            .van-icon-arrow-left{
                position: relative;
                top: -2px;
                background: #fff;
                width: 25px;
                height: 25px;
                text-align: center;
                line-height: 25px;
                overflow: hidden;
                border-radius: 25px;
                color: #415FD5;
                box-sizing: border-box;
                font-size: 18px;
                font-weight: bold;
                vertical-align: middle;
                margin-right: 10px;
            }
            .txt{
                height: 27px;
                font-size: 16px;
                font-family: SourceHanSansSC-Normal, SourceHanSansSC;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 27px;
                display: inline-block;
            }
        }
        .content{
            margin-top: 16px;
            margin-bottom: 32px;
            width: 343px;
            // min-height: 257px;
            background: #FFFFFF;
            box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.2);
            border-radius: 12px;
            overflow: hidden;
            &-top{
                height: 47px;
                padding: 0 16px;
                padding-top: 15px;
                label{
                    height: 20px;
                    font-size: 14px;
                    font-family: SourceHanSansSC-Normal, SourceHanSansSC;
                    font-weight: 400;
                    color: #2A3155;
                    line-height: 20px;
                    float: left;
                }
                .time-out{
                    text-align: center;
                }
                .img{
                    width: 18px;
                    float: right;
                    padding-top: 5px;
                }
            }
            &-info{
                width: 343px;
                // background: #F4F6FD;
                border-radius: 2px;
                &-box{
                    padding: 0 20px;
                    overflow: auto;
                    min-height: 20px;
                    min-height: 38px;
                    padding-bottom: 20px;
                    color: #606580;
                    &.err{
                        background: rgba(255, 66, 54, 0.08);
                        position: relative;
                        &::after{
                            position: absolute;
                            bottom: 0;
                            right: 10px;
                            content: '';
                            width: 100px;
                            height: 76px;
                            background: url('../../assets/img/img_7.png') no-repeat;
                            background-size: 100%;
                        }
                    }
                    &.succ{
                        background: rgba(21, 195, 146, 0.1);
                        position: relative;
                        &::after{
                            position: absolute;
                            bottom: 0;
                            right: 10px;
                            content: '';
                            width: 100px;
                            height: 76px;
                            background: url('../../assets/img/img_8.png') no-repeat;
                            background-size: 100%;
                        }
                    }
                }
                #topicMain{
                    padding-top: 10px;
                    text-align: center;
                }
                ul{
                    padding-top: 16px;
                    padding-bottom: 8px;
                    li{
                        width: 311px;
                        margin: 0 auto;
                        margin-bottom: 8px;
                        text-align: center;
                        min-height: 36px;
                        line-height: 36px;
                        background: #FFFFFF;
                        border-radius: 2px;
                        border: 1px solid #E6E8EF;
                    }
                }
                .btm-con{
                    padding-top: 16px;
                    background: #fff;
                    .btn{
                        &-con{
                            margin: 0 16px;
                            padding-bottom: 20px;
                            border-bottom: 1px solid #E6E8EF;
                            margin-bottom: 45px;
                        }
                        &-list{
                            width: 104px;
                            height: 40px;
                            background: #E6E8EF;
                            border-radius: 24px;
                            display: inline-block;
                            text-align: center;

                            font-size: 18px;
                            font-family: MicrosoftYaHei;
                            color: #2A3155;
                            line-height: 40px;
                            &.sub{
                                width: 195px;
                                margin-right: 12px;
                                background: #F9C200;

                                font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                                font-weight: bold;
                                color: #FFFFFF;
                            }
                            &.succ{
                                width: 195px;
                                margin-right: 12px;
                                background: linear-gradient(90deg, #F9C200 0%, #F97A00 100%);

                                font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                                font-weight: bold;
                                color: #FFFFFF;
                            }
                            &.err{
                                background: #415FD5;
                                width: 143px;
                                font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                                font-weight: bold;
                                color: #FFFFFF;
                            }
                            &.icon{
                                width: 40px;
                                margin-left: 12px;
                            }
                        }
                    }

                }
            }
        }
        .step{
            .p1{
                height: 31px;
                font-size: 21px;
                font-family: SourceHanSansSC-Heavy, SourceHanSansSC;
                font-weight: 800;
                color: #FFFFFF;
                line-height: 31px;
                padding-bottom: 12px;
            }
            .van-collapse{
                &::after{
                    border: none;
                }
            }
            .van-collapse-item{
                margin-bottom: 12px;
                min-height: 50px;
                background: #FFFFFF;
                box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.2);
                border-radius: 12px;
                overflow: hidden;
            }
            .step-info-list{
                overflow: auto;
            }
        }
        .xy_jg{
            width: 195px;
            height: 48px;
            background: #F9C200;
            box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.2);
            border-radius: 24px;

            font-size: 18px;
            font-family: SourceHanSansSC-Heavy, SourceHanSansSC;
            font-weight: 800;
            color: #FFFFFF;
            line-height: 48px;
            display: inline-block;
        }
        .zongjie{
            position: fixed;
            right: 24px;
            bottom: 24px;
            width: 48px;
            height: 48px;
            background: #FFFFFF;
            box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.2);
            border-radius: 30px;
            opacity: 0.98;
            border: 2px solid #fff;
            text-align: center;
            &.on{
                border: 2px solid #F9C200;
            }
            .p1{
                height: 25px;
                .iconfont{
                    font-size: 16px;
                }
            }
            .p2{
                height: 18px;
                font-size: 12px;
                font-family: SourceHanSansSC-Normal, SourceHanSansSC;
                font-weight: 400;
                color: #2A3155;
                line-height: 18px;
            }
            .qipaoZj{
                box-shadow: 0px 2px 8px 0px rgba(52, 60, 101, 0.2);
                display: none;
                position: absolute;
                height: 20px;
                padding: 6px 8px;
                width: 130px;
                border-radius: 7px;
                top: -40px;
                left: -95px;
                background: #fff;
                &::after{
                    content: '';
                    width: 0;
                    height: 0;
                    // border: 5px solid transparent;
                    border: 5px solid;
                    border-color: #fff transparent transparent transparent;
                    
                    // border-bottom-color: #fff ;
                    position: absolute;
                    bottom: -10px;
                    right: 22px;
                    transform: rotate(180);
                }
                &.on{
                    display: block;
                }
            }
        }
        .xiayiti{
            position: fixed;
            left: 24px;
            bottom: 24px;
            width: 48px;
            height: 48px;
            background: #FFFFFF;
            box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.2);
            border-radius: 30px;
            opacity: 0.98;
            border: 2px solid #fff;
            text-align: center;
            line-height: 48px;
            color: #415FD5;
            font-weight: bold;
        }
    }
</style>