<template>
    <van-popup
        v-model="$store.state.special.showNav"
        closeable
        position="left"
        :style="{ width: '8.75rem',height: '100%' }">
        <div class="navLeft">
            <div class="nav-top">
                <div class="nav-top-title">
                    <div class="goback-dx" @click="goToGuide">
                        章节列表
                    </div>
                    <div class="name mt_15">
                        第{{query.number}}章：{{query.navInfo.name}}
                    </div>
                </div>
            </div>

            <div class="nav-con">
                <ul>
                    <template v-for="(item,n) in leftNavInfo.childs">
                        <li class="pr_8" :key="n" @click="navClick(item,n)">
                            <div v-if="this_topic == item.name" class="nav-list-con on p_16 ">
                                <div class="txt dis_flex dis_sb">
                                    {{n+1}}、{{item.name}}
                                    <van-icon class="mt_5" name="arrow" />
                                </div>
                                <span class="jindu"><span class="pair" :style="'width:'+ (jd_stars || item.stars_count) +'%'"></span></span>
                            </div>
                            <div v-else class="nav-list-con dis_flex dis_sb p_16 ">
                                <span class="txt">{{n+1}}、{{item.name}}</span>
                                <span class="jindu"><span class="pair" :style="'width:'+ item.stars_count +'%'"></span></span>
                            </div>
                        </li>
                    </template>
                </ul>
            </div>
        </div>
    </van-popup>
</template>

<script>
    export default {
        data() {
            return {
                star: 3,
                activeNames: [0],
                activeNames1:[],
                active:'0_0',//菜单选中
                active1:'',//真题菜单选中
                numList:['一','二','三','四','五','六','七','八','九','十'],
                numChange:[],
                thatInfo:{}, //当前选中的信息
                parentName:'',// 
                navList: [],
                level:1,


                query: {},

                this_topic: '',  // 当前所在专题

            }
        },
        props: {
            showNum: {
                default: 0
            }
        },
        computed: {
            leftNavInfo() {
                return this.$store.state.special && this.$store.state.special.leftNavInfo;
            },
            jd_q(){
                return this.$store.state.jd_q;
            },
            jd_stars () {
                return this.$store.state.jd_stars;
            }
        },
        methods: {
            navClick(item,n){
                let navInfo = item;
                navInfo.n = n;
                console.log('navInfo--eqweqw-', navInfo);
                this.$store.commit('setNavInfo', navInfo);

                this.this_topic = item.name;

                this.$store.commit('settopicTotal',item.childs[0].total)

                this.$store.commit('setThis_topic', this.this_topic);

                this.user_info_write(navInfo)
            },
            // 获取用户当前进度
            async user_info_query(){
                let param = {
                    book: this.query.book
                };
                await this.$api.user_info_query({param,method:'get'},res=>{
                    console.log('user_info_query', res);
                    let this_topic = res['当前专题名称'];
                    this.this_topic = this_topic;
                });
            },
            getNumBox(){
                let numChange=[];
                let numList = ['一','二','三','四','五','六','七','八','九','十'];
                numList.map((e,n)=>{
                    numChange.push(e)
                })
                // this.numDemo = 
            },
            user_info_write(navInfo){
                let param = {
                    zhuanti: navInfo.name,
                    dangqianwancheng: '',
                    book: this.query.book,
                    exam_idx: 0,
                }
                this.$api.user_info_write({param,method:'post'},res=>{
                    console.log('user_info_write', res);
                });
            },
            async jindu_query(){
                let query = this.$route.query;
                let param ={
                    username: localStorage.username,
                    book: query.book
                };
                await this.$api.jindu_query({param,method:'post'},res=>{
                    console.log('jindu_query', res);
                    let list = res;
                    let obj;
                    res.map(e=>{
                        if(e.zhuanti == "当前进度"){
                            obj = e.obj;
                        }
                    });
                    localStorage.leftInfo = obj;
                    obj = JSON.parse(obj);
                    console.log('obj', obj);
                    let all_total = 0;
                    let all_trueNumTopic = 0;
                    res.map(e=>{
                        if(obj.data.name == e.zhuanti){
                            let data = JSON.parse(e.obj);
                            data = data.data;
                            let arr = data.childs;
                            arr.map((el,i)=>{
                                all_total += el.total;
                                all_trueNumTopic += (el.trueNumTopic||0);
                                if(el.total == el.trueNumTopic){
                                    if(i+1==arr.length){
                                        this.level = i+1;
                                    }else{
                                        this.level = i+2;
                                    }
                                }else{
                                    this.level = i+1;
                                }
                            })
                        }
                    });
                    let start = (all_trueNumTopic/all_total)*5;
                    start = parseInt(start);
                    this.star = start;
                });
            },
            async getLevel(){
                let param ={
                    username: localStorage.username
                };
                await this.$api.jindu_query({param,method:'post'},res=>{
                    let list = res;
                    let obj;
                    res.map(e=>{
                        if(e.zhuanti == "当前进度"){
                            obj = e.obj;
                        }
                    });
                    localStorage.leftInfo = obj;
                    obj = JSON.parse(obj);
                    console.log('obj', obj);
                    let all_total = 0;
                    let all_trueNumTopic = 0;
                    res.map(e=>{
                        if(obj.data.name == e.zhuanti){
                            let data = JSON.parse(e.obj);
                            data = data.data;
                            let arr = data.childs;
                            arr.map((el,i)=>{
                                all_total += el.total;
                                all_trueNumTopic += (el.trueNumTopic||0);
                                if(el.total == el.trueNumTopic){
                                    if(i+1==arr.length){
                                        this.level = i+1;
                                    }else{
                                        this.level = i+2;
                                    }
                                }else{
                                    this.level = i+1;
                                }
                            })
                        }
                    });
                })
            },
            goToGuide(){
                let query = this.$route.query;
                this.$router.push({path:'/guide',query:{name:query.book}})
                setTimeout(() => {
                    location.reload();
                }, 100);
            }
        },
        async created () {
            let query = this.$route.query;
            let navInfo = query.navInfo;
            if(typeof navInfo == 'string'){
                query.navInfo = JSON.parse(navInfo);
            }
            console.log('query---------', query);
            this.query = query;
            this.$store.commit('setleftNavInfo',navInfo);

            await this.user_info_query();
            if(this.this_topic){
                let a=true;
                navInfo.childs.map(e=>{
                    if(e.name == this.this_topic){
                        this.navClick(e,e.current_level);
                        a=false;
                    }
                });
                if(a){
                    this.navClick(query.navInfo.childs[0],0);
                }
            } else {
                this.navClick(query.navInfo.childs[0],0);
            }
            
        },
    }
</script>

<style lang="less" scoped>
    .navLeft{
        .nav-top{
            padding: 48px 16px 24px 16px;
            &-title{
                .goback-dx{
                    height: 24px;
                    font-size: 16px;
                    font-family: SourceHanSansSC-Normal, SourceHanSansSC;
                    font-weight: 400;
                    color: #3364FF;
                    line-height: 24px;
                }
                .name{
                    height: 29px;
                    font-size: 20px;
                    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                    font-weight: bold;
                    color: #2A3155;
                    line-height: 29px;
                }
            }
        }
        .nav-con{
            ul{
                li{
                    .nav-list-con{
                        width: 248px;
                        height: 51px;
                        background: rgba(65, 95, 213, 0.1);
                        border-radius: 12px;
                        box-sizing: border-box;
                        margin-left: 16px;
                        margin-bottom: 12px;
                        &.on{
                            color: #fff;
                            height: 80px;
                            background: #415FD5;
                            .jindu{
                                width: 100%;
                            }
                        }
                    }
                    .jindu{
                        // margin-top: 12px;
                        width: 60px;
                        height: 8px;
                        background: #fff;
                        border-radius: 5px;
                        display: inline-block;
                        // overflow: hidden;
                        position: relative;
                        .pair{
                            background: #F9C200;
                            position: absolute;
                            border-radius: 5px;
                            // top: 0.4px;
                            left: 0px;
                            height: 8px;
                        }
                    }
                }
            }
        }
    }
</style>