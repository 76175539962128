<template>
    <div class="special">
        <template v-if="isStatus">
            <div>
                <!-- <qianghua ref="qianh" /> -->
                <qianghua ref="qianh" @getName="getName" />
                <!-- <youxian v-else ref="qianh" @getName="getNameYx" /> -->
            </div>
        </template>
        
        <navLeft ref="nav" :active="active" />
        <!-- <navLeftYx v-else ref="nav" :active="active" /> -->

        <div class="tabbar" v-if="!$store.state.special.fenbu">
            <ul>
                <li @click="navClick">
                    <p class="p1"><van-icon name="bars" /></p>
                    <p class="p2">目录</p>
                </li>
                <li @click="daoXuan_1">
                    <p class="p1"><van-icon name="apps-o" /></p>
                    <p class="p2">导学</p>
                </li>
                <li @click="kaogang">
                    <p class="p1"><van-icon name="apps-o" /></p>
                    <p class="p2">考纲</p>
                </li>
                <!-- <li @click="showZj = true">
                    <p class="p1">
                        <i class="iconfont icongeneral-daoxue"></i>
                    </p>
                    <p class="p2">总结</p>
                </li> -->

                <!-- <li @click="showTopic"  v-if="!infi">
                    <p class="p1"><van-icon name="apps-o" /></p>
                    <p class="p2">历史</p>
                </li> -->
            </ul>
        </div>

        <van-image-preview v-model="showImg" :images="images">
            <!-- <template v-slot:index>第{{ index }}页</template> -->
        </van-image-preview>

        <van-popup v-model:show="showZj" position="bottom" round :style="{ height: '50%' }" closeable close-icon="close">
            <div class="zongjie">
                <div class="title">总结</div>

                <div class="weizhi">
                    位置：{{query.navInfo.name}} - {{navInfo.name}}
                    <span v-if="buzhou != '-1'">- 第{{number[buzhou]}}步 </span>
                </div>

                <div v-if="!isAddShow" @click="isAddShow = true" class="zongjie-add">新增总结</div>
                <div v-else>
                    <div style="border: 1px solid #B9BFD3;" class="mt_15 mb_15 zj-msg">

                        <van-field
                            v-model="message"
                            rows="2"
                            @click="zjTs = true"
                            autosize
                            label=""
                            type="textarea"
                            maxlength="30"
                            placeholder=""
                            show-word-limit
                        />
                        <div class="zjTs-prop" v-if="zjTs && $store.state.special.topicData['总结'] && $store.state.special.topicData['总结'][0] && $store.state.special.topicData['总结'].length>0">
                            <p class="p1">专题难点 <van-icon class="fr" @click="zjTs = false" name="cross" /></p>
                            <ul>
                                <li @click="liClick(item)" v-for="(item,n) in $store.state.special.topicData['总结']" :key="n">{{item}}</li>
                            </ul>
                        </div>
                    </div>
                    <van-button style="background: #415FD5;" type="info" @click="zongjie_write" round block>保  存</van-button>
                </div>
                <div class="title pt_24" v-if="zongjieList && zongjieList.length">本专题已有 {{zongjieList.length}} 条总结</div>

                <div class="zongjie-list" v-for="(item,i) in zongjieList" :key="i">
                    <span class="icon">
                        <img src="@/assets/img/weizhi.png" class="img" alt="">
                    </span>
                    <p class="p1">{{query.navInfo.name}} - {{navInfo.name}} <span v-if="item[0] != -1">- 第{{number[i]}}步 </span></p>
                    <p class="p2">{{item}}</p>
                </div>
                <!-- <div class="zongjie-list">
                    <span class="icon">
                        <img src="@/assets/img/weizhi.png" class="img" alt="">
                    </span>
                    <p class="p1">行列式-公式法求解（三级）-第二步</p>
                    <p class="p2">公式记忆模糊，二阶的混在一起了</p>
                </div> -->
                <p class="p3">没有更多内容</p>

            </div>
        </van-popup>

        <van-popup v-model:show="showCheckTopic" title="" closeable position="bottom">
            <!-- <img src="https://img01.yzcdn.cn/vant/apple-3.jpg" /> -->
            <div class="checkTopic-con">
                <ul>
                    <li v-for="(item,n) in topicTotal" :key="n" @click="checkTopic(n)">{{item}}</li>
                </ul>
            </div>
        </van-popup>

        
    </div>
</template>

<script>
    import navLeft from './component/leftNav';
    // import navLeftYx from './component/leftNavYx';
    import qianghua from './qianghua';
    // import youxian from './youxian';
    import utils from '@/assets/js/utils';
    export default {
        name:'special',
        data() {
            return {
                showImgDialog: false,
                showImg: false,
                images:[],


                zjTs: false,
                showDx: false,
                query: '',
                navLeftShow: false,
                active: '',
                showCheckTopic: false,

                message: '',
                showZj: false,

                zongjieList:[],
                number: ["一","二","三","四","五","六","七","八","九"],

                isAddShow: false,

                buzhou: '-1',

                infi: true,

                isStatus: false,

                // topicTotal: 0,
            }
        },
        components: {
            navLeft,
            // navLeftYx,
            qianghua,
            // youxian,
        },
        computed: {
            navInfo(){
                let navInfo  = this.$store.state.special.navInfo;
                this.isStatus = false;
                if(navInfo && navInfo.childs.length>0){
                    let infi = navInfo.infi;
                    this.infi = infi;
                }
                this.$nextTick(e=>{
                    this.isStatus = true;
                })
                return navInfo
            },
            topicTotal(){
                return this.$store.state.special.topicTotal || 0;
            }
        },
        created () {
            let query = this.$route.query;
            let navInfo = query.navInfo;
            if(typeof navInfo == 'string'){
                query.navInfo = JSON.parse(navInfo);
            }
            console.log('query', query);
            this.query = query;

            let listName = localStorage.listName;
            let active = '';
            if(listName && listName !== 'undefined'){
                if(typeof listName == 'string'){
                    listName = JSON.parse(listName);
                }
                let arr = Object.keys(listName);
                if(arr.includes(query.navInfo.name)){
                    active = 'qianghua';
                }
            }
            this.active = active;
        },
        watch: {
            showZj(newValue, oldValue) {
                if(newValue){
                    this.zongjie_query();
                }
            }
        },
        methods: {
            checkTopic(n){
                this.$refs.qianh.exam_idx = n;
                this.$refs.qianh.clearDjs();
                this.$refs.qianh.init();
                this.$refs.qianh.djs(this.navInfo);
                // this.$refs.qianh.getDir(this.navInfo);
                
                this.showCheckTopic = false;
            },
            showTopic(){
                this.showCheckTopic = true;
            },
            getName(e,n){
                console.log(1111,e,n)
                this.$refs.nav.navClick(e,n)
            },
            getNameYx(e,n){
                console.log('1111Yx',e,n)
                this.$refs.nav.navClick(e,n)
                this.$refs.nav.checkTopic(n,e)
            },
            liClick(e){
                this.message = this.message+e;
                this.zjTs = false;
            },
            // 查询总结
            zongjie_query(){
                let topicData = this.$store.state.special.topicData;
                let navInfo = this.navInfo;
                let dir = navInfo.childs[navInfo.current_level||0].dir;
                let arr = dir.split('/');
                let zhuanti = arr[arr.length-1];
                let param = {
                    zhuanti: zhuanti,
                    book: this.$route.query.book,
                    exam_idx: topicData['索引'],
                }
                this.$api.zongjie_query({param},res=>{
                    console.log('zongjie',res);
                    if(res==null){
                        this.zongjieList = [];
                    }else{
                        this.zongjieList = res.contents;
                    }
                });
            },
            // 写入
            zongjie_write(){ 
                if(this.message ==''){
                    this.$toast('请输入总结内容！')
                    return false;
                }
                let topicData = this.$store.state.special.topicData;
                let navInfo = this.navInfo;
                let dir = navInfo.childs[navInfo.current_level||0].dir;
                let arr = dir.split('/');
                let zhuanti = arr[arr.length-1];
                let param = {
                    zhuanti: zhuanti,
                    book: this.$route.query.book,
                    buzhou: this.buzhou,
                    exam_idx: topicData['索引'],
                    content: this.message,
                }
                console.log('param', param);
                this.$api.zongjie_write({param,method:'post'},res=>{
                    if(res.status == "200"){
                        this.message = '';
                        this.$toast('添加成功');
                        this.zongjie_query();
                    }else{
                        this.$toast('添加失败，请重试！')
                    }
                });
            },
            navClick(){
                let navLeftShow = this.navLeftShow;
                navLeftShow = navLeftShow?false:true;
                let topicData = this.$store.state.special.topicData;
                if(topicData['索引']!=999){
                    this.$refs.nav.getNavList();
                }
                this.$store.commit('setNav',navLeftShow);
            },
            daoXuan_1(){
                this.$refs.qianh.daoxueEvent();
            },
            daoXuan(){
                // http://123.57.47.165:56728/intro/hanshudegainian
                // this.$refs.qianh.daoxueEvent();
                // solution_dir
                console.log('this.navInfo', this.navInfo);
                this.$ajax({url: this.navInfo.childs[0].solution_dir, data:{},
                    success:(res=>{
                        // console.log('img',res.img);
                        let imgSrc = 'data:image/jpeg;base64,'+res.img;
                        // this.images[0] = imgSrc;
                        this.$set(this.images,0,imgSrc);
                    })
                })
                this.showImg = true;
            },
            kaogang(){
                this.$refs.qianh.showKg = true;
            },
            goFenbu(){
                this.$store.commit('setFenbu', true);
            }
        },
        destroyed () {
            this.$store.commit('setFenbu', false);
        },
    }
</script>

<style lang="less" scoped>
    .special{
        background: #415FD5;
        padding: 16px;
        text-align: left;

        .tabbar{
            position: fixed;
            bottom: 16px;
            left: 38px;
            width: 300px;
            height: 48px;
            background: #fff;
            z-index: 9;
            margin: 0 auto;
            border-radius: 48px;
            box-sizing: border-box;

            box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.2);
            ul{
                padding: 6px 0px;
                display: flex;
                font-size: 12px;
                display: flex;
                li{
                    width: 24px;
                    // margin-right: 46px;
                    flex-grow: 4;
                    text-align: center;
                    line-height: 1.2;
                    // &:nth-child(4n){
                    //     margin-right: 0;
                    // }
                    .p1{
                        font-size: 18px;
                        height: 18px;
                        display: inline-block;
                        .van-icon{
                            font-size: #707693;
                        }
                        .iconfont{
                            width: 18px;
                            font-size: 18px;
                        }
                    }
                    .p2{
                        font-size: 12px;
                        color: #2A3155;
                    }
                }
            }
        }
        .zongjie{
            padding: 16px;
            &-add{
                width: 343px;
                height: 40px;
                background: #F9C200;
                border-radius: 24px;
                margin-top: 15px;
                font-size: 18px;
                line-height: 40px;
                text-align: center;
                color: #fff;
            }
            .title{
                height: 21px;
                font-size: 18px;
                font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                font-weight: bold;
                color: #2A3155;
                line-height: 21px;
                padding-bottom: 16px;
            }
            .weizhi{
                height: 19px;
                font-size: 14px;
                font-family: MicrosoftYaHei;
                color: #606580;
                line-height: 19px;
            }
            &-list{
                margin-bottom: 10px;
                position: relative;
                padding: 16px 26px;
                background: #ECEFFA;
                border-radius: 4px;
                .icon{
                    position: absolute;
                    top: 12px;
                    left: -8px;
                    padding: 5px;
                    display: inline-block;
                    background: #415FD5;
                    box-shadow: 1px 1px 4px 0px rgba(42, 49, 85, 0.1);
                    border-radius: 14px;
                    line-height: 0;
                    .img{
                        width: 16px;
                        height: 16px;
                    }
                }
                .p1{
                    font-size: 14px;
                    font-family: MicrosoftYaHei;
                    color: #2A3155;
                    line-height: 19px;
                    padding-bottom: 8px;
                }
                .p2{
                    font-size: 14px;
                    font-family: MicrosoftYaHei;
                    color: #9498AA;
                    line-height: 19px;
                }
            }
            .p3{
                padding-top: 23px;
                padding-bottom: 23px;
                text-align: center;
                height: 19px;
                font-size: 14px;
                font-family: MicrosoftYaHei;
                color: #BFC1CC;
                line-height: 19px;
            }
            /deep/textarea::-webkit-input-placeholder{
                color:#666;
                }
            /deep/textarea::-moz-placeholder{   /* Mozilla Firefox 19+ */
                color:#666;
            }
            /deep/textarea:-moz-placeholder{    /* Mozilla Firefox 4 to 18 */
                color:#666;
            }
            /deep/textarea:-ms-input-placeholder{  /* Internet Explorer 10-11 */ 
                color:#666;
            }

        }
        .zj-msg{
            position: relative;
            .zjTs-prop{
                position: absolute;
                top: 50px;
                left: 10px;
                background: #fff;
                padding: 16px;
                width: 285px;
                box-shadow: 0px 0px 12px 0px rgba(42, 49, 85, 0.2);
                z-index: 999;
                .p1{
                    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                    font-weight: bold;
                    color: #2A3155;
                    line-height: 19px;
                }
                li{
                    background: #FEF8E5;
                    border-radius: 2px;
                    margin: 8px 4px;
                    line-height: 43px;
                    padding-left: 16px;
                }
            }
        }
        .checkTopic-con{
            padding: 50px 20px;
            ul{
                // text-align: center;
                display: flex;
                // justify-content: center;
                flex-wrap: wrap;
            }
            li{
                width: 17vw;
                height: 17vw;
                display: inline-block;
                background:rgb(15, 74, 201);
                color: #fff;
                margin: 10px;
                font-size: 18px;
                border-radius: 90px;
                text-align: center;
                line-height: 17vw;
            }
        }
    }
</style>